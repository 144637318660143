import MDTypography from 'components/MDTypography';
import numeral from "numeral";
import moment from 'moment';
import { Icon } from '@mui/material';
import MDBox from 'components/MDBox';
import YADataTable from 'components/YADataTable';
import { useEffect, useMemo, useRef } from 'react';
import { useAppController } from 'context';
import { toNumber } from 'utils';
import { formatCurrency } from 'utils/charts';
import { formatDecimal } from 'utils/charts';
import { useAnalyticsContext } from 'context/AnalyticsContext';
import { useWidgetItemContext } from 'context/WidgetItemContext';

const getCellValue = (value) => {
    return Array.isArray(value) ? value[0] : value;
}

const getChildColumnHeaderDetails = (pivotTable, colDefTitle, col, columnsDef) => {
    let getColumnDef = (col, columnDefs) => {
        let normalizeString = (str) => {
            return str.replace(/\s+/g, '').replace(/\./g, '').toLowerCase();
        }
        for (let def of columnDefs) {
            let normalizedTitle = normalizeString(col.title)
            let normalizedName = normalizeString(def.name)
            if (normalizedTitle.includes(normalizedName) && def.dataType === "time") {
                return def
            }
        }
        return null
    }
    let timeDef = getColumnDef(col, columnsDef);
    let handleTimeFormat = (value) => {
        if (timeDef && value) {
            value = moment(value).format(timeDef.dateFormat || "DD/MM/YYYY");
        }
        return value;
    }
    const accessor = col.key ?
        Array.isArray(col.key) ?
            col.key.join("__").replace(/\./g, "__")
            : String(col.key).replace(/\./g, "__")
        : "";
    const header = !pivotTable ?
        (colDefTitle || col.shortTitle)
        : !col.key ? "(empty)"
            : Array.isArray(col.shortTitle) ?
            handleTimeFormat(col.shortTitle[0])
                : handleTimeFormat(col.shortTitle);
    return { accessor, header };
}

const buildChildColumns = (pivotTable, defaultDateFormat, measures, columnsDef, parentColumn, parentKey) => parentColumn.children?.map(
    (col) => {
        const colDef = columnsDef?.find(c => c.name === col.key);
        if (col.children) {
            const { header, accessor } = getChildColumnHeaderDetails(pivotTable, colDef?.titleAlias || colDef?.title, col, columnsDef);
            return {
                Header: header,
                accessor: `${parentKey}__${accessor}`,
                disableSorting: true,
                columns: buildChildColumns(pivotTable, defaultDateFormat, measures, columnsDef, col, `${parentKey}__${accessor}`),
                Footer: () => null,
            }
        }
        else {
            const accessor = `${parentKey}__${String(col.key).replace(/\./g, "__")}`;
            return {
                Header:  colDef?.titleAlias || colDef?.title || col.shortTitle,
                align: ["currency", "number", "decimal"].includes(colDef?.dataType) ? "right" : "left",
                accessor: accessor,
                disableSorting: true,
                Cell: ({ cell: { value } }) => {
                    if (colDef?.dataType === "currency")
                        return <MDTypography key={accessor} variant="caption" color="dark" fontWeight={colDef?.emphasize && "medium"}>{formatCurrency(value, colDef?.decimalPoints)}</MDTypography>
                    else if (colDef?.dataType === "decimal")
                        return <MDTypography key={accessor} variant="caption" color="dark" fontWeight={colDef?.emphasize && "medium"}>{formatDecimal(value, colDef?.decimalPoints)}</MDTypography>
                    else if (colDef?.dataType === "number")
                        return <MDTypography key={accessor} variant="caption" color="dark" fontWeight={colDef?.emphasize && "medium"}>{formatDecimal(value, 0)}</MDTypography>
                    else if (colDef?.dataType === "percent")
                        return <MDTypography key={accessor} variant="caption" color="dark" fontWeight={colDef?.emphasize && "medium"}>{numeral(value).format('0.0%')}</MDTypography>
                    else if (colDef?.dataType === "boolean")
                        return <MDTypography key={accessor} variant="caption" color="dark" fontWeight={colDef?.emphasize && "medium"}>{value ? "Yes" : "No"}</MDTypography>
                    else if (colDef?.dataType === "time")
                        return <MDTypography key={accessor} variant="caption" color="dark" fontWeight={colDef?.emphasize && "medium"}>{value ? moment(value).format(colDef?.dateFormat || defaultDateFormat) : ""}</MDTypography>
                    return <MDTypography key={accessor} variant="caption" color="dark" fontWeight={colDef?.emphasize && "medium"}>{getCellValue(value) || "(empty)"}</MDTypography>
                },
                Footer: info => {
                    if (!measures.includes(col?.key) || !["currency", "number", "decimal"].includes(colDef?.dataType))
                        return <span></span>
                    const total = useMemo(
                        () =>
                            info.rows.reduce((sum, row) => toNumber(row.values[accessor]) + sum, 0),
                        [info.rows]
                    )
                    if (colDef?.dataType === "decimal")
                        return <MDTypography key={"accessor"} variant="caption" fontWeight="medium" color="dark">{formatDecimal(total, colDef?.decimalPoints)}</MDTypography>
                    else if (colDef?.dataType === "number")
                        return <MDTypography key={"accessor"} variant="caption" fontWeight="medium" color="dark">{formatDecimal(total, 0)}</MDTypography>
                    return <MDTypography key={"accessor"} variant="caption" fontWeight="medium" color="dark">{formatCurrency(total, colDef?.decimalPoints)}</MDTypography>
                }
            }
        }
    }
);

const buildColumns = (pivotTable, defaultDateFormat, measures, columnsDef, colDef, col) => {
    if (col?.children) {
        const { header, accessor } = getChildColumnHeaderDetails(pivotTable, colDef?.titleAlias || colDef?.title, col, columnsDef);
        return {
            Header: header,
            accessor: accessor,
            disableSorting: true,
            columns: buildChildColumns(pivotTable, defaultDateFormat, measures, columnsDef, col, accessor),
            Footer: () => null,
        };
    }
    else {
        const accessor = String(col ? col.key : colDef.name).replace(/\./g, "__");
        return {
            Header: (colDef?.titleAlias || colDef?.title || col.shortTitle),
            align: ["currency", "number", "decimal"].includes(colDef?.dataType) ? "right" : "left",
            accessor: accessor,
            disableSorting: true,
            dataType:colDef?.dataType,
            decimalPoints:colDef?.decimalPoints,
            dateFormat:colDef?.dateFormat,
            hideTotal:colDef?.hideTotal,
            Cell: ({ cell: { value } }) => {
                if (colDef?.dataType === "currency")
                    return <MDTypography key={accessor} variant="caption" color="dark" fontWeight={colDef?.emphasize && "medium"}>{formatCurrency(value, colDef?.decimalPoints)}</MDTypography>
                else if (colDef?.dataType === "decimal")
                    return <MDTypography key={accessor} variant="caption" color="dark" fontWeight={colDef?.emphasize && "medium"}>{formatDecimal(value, colDef?.decimalPoints)}</MDTypography>
                else if (colDef?.dataType === "number")
                    return <MDTypography key={accessor} variant="caption" color="dark" fontWeight={colDef?.emphasize && "medium"}>{formatDecimal(value, 0)}</MDTypography>
                else if (colDef?.dataType === "percent")
                    return <MDTypography key={accessor} variant="caption" color="dark" fontWeight={colDef?.emphasize && "medium"}>{numeral(value).format('0.0%')}</MDTypography>
                else if (colDef?.dataType === "boolean")
                    return <MDTypography key={accessor} variant="caption" color="dark" fontWeight={colDef?.emphasize && "medium"}>{value ? "Yes" : "No"}</MDTypography>
                else if (colDef?.dataType === "time" || colDef?.dataType === "date")
                    return <MDTypography key={accessor} variant="caption" color="dark" fontWeight={colDef?.emphasize && "medium"}>{value ? moment(value).format(colDef?.dateFormat || defaultDateFormat) : ""}</MDTypography>
                return <MDTypography key={accessor} variant="caption" color="dark" fontWeight={colDef?.emphasize && "medium"}>{getCellValue(value) || "(empty)"}</MDTypography>
            },
            Footer: info => {
                if (!measures.includes(String(col ? col.key : colDef.name)) || !["currency", "number", "decimal"].includes(colDef?.dataType))
                    return <span></span>
                const total = useMemo(
                    () =>
                        info.rows.reduce((sum, row) => toNumber(row.values[accessor]) + sum, 0),
                    [info.rows]
                )
                if (colDef?.dataType === "decimal")
                    return <MDTypography key={`f_${accessor}`} variant="caption" fontWeight="medium" color="dark">{formatDecimal(total, colDef?.decimalPoints)}</MDTypography>
                else if (colDef?.dataType === "number")
                    return <MDTypography key={`f_${accessor}`} variant="caption" fontWeight="medium" color="dark">{formatDecimal(total, 0)}</MDTypography>
                return <MDTypography key={`f_${accessor}`} variant="caption" fontWeight="medium" color="dark">{formatCurrency(total, colDef?.decimalPoints)}</MDTypography>
            },
        };
    }
};

const buildGrandTotalsColumnsGroup = (measures, pivotConfig, columnsDef) => {
    if (pivotConfig.y?.length > 1 && measures) {
        let columns = measures?.map(m => {
            const colDef = columnsDef?.find(c => c.name === m);
            const accessor = String(m).replace(/\./g, "__");
            return {
                Header: `Total ${colDef?.titleAlias || colDef?.title}`,
                align: "right",
                accessor: accessor,
                disableSorting: true,
                grandTotal: true,
                Cell: ({ cell: { value } }) => {
                    if (colDef?.dataType === "currency")
                        return <MDTypography key={accessor} variant="caption" color="dark" fontWeight="medium">{formatCurrency(value, colDef?.decimalPoints)}</MDTypography>
                    else if (colDef?.dataType === "decimal")
                        return <MDTypography key={accessor} variant="caption" color="dark" fontWeight="medium">{formatDecimal(value, colDef?.decimalPoints)}</MDTypography>
                    else if (colDef?.dataType === "number")
                        return <MDTypography key={accessor} variant="caption" color="dark" fontWeight="medium">{formatDecimal(value, 0)}</MDTypography>
                    return <MDTypography key={accessor} variant="caption" color="dark" fontWeight="medium">{getCellValue(value) || "(empty)"}</MDTypography>
                },
                Footer: info => {
                    if (!["currency", "number", "decimal"].includes(colDef?.dataType))
                        return <span></span>
                    const total = useMemo(
                        () =>
                            info.rows.reduce((sum, row) => toNumber(row.values[accessor]) + sum, 0),
                        [info.rows]
                    )
                    if (colDef?.dataType === "decimal")
                        return <MDTypography key={"accessor"} variant="caption" fontWeight="medium" color="dark">{formatDecimal(total, colDef?.decimalPoints)}</MDTypography>
                    else if (colDef?.dataType === "number")
                        return <MDTypography key={"accessor"} variant="caption" fontWeight="medium" color="dark">{formatDecimal(total, 0)}</MDTypography>
                    return <MDTypography key={"accessor"} variant="caption" fontWeight="medium" color="dark">{formatCurrency(total, colDef?.decimalPoints)}</MDTypography>
                },
            }
        });

        let columnsGroup = {
            Header: "Grand Total",
            accessor: "grandTotal",
            columns: []
        };

        let currentColumnRef = columnsGroup;
        if (pivotConfig.y.length > 2) {
            pivotConfig.y?.forEach((d, i) => {
                if (i > 0 && i < pivotConfig.y.length - 1) {
                    let currentColumn = {
                        Header: "",
                        accessor: d,
                        columns: [],
                        grandTotal: true,
                    };

                    currentColumnRef.columns.push(currentColumn);
                    currentColumnRef = currentColumn;
                }
            });
        }

        currentColumnRef.columns = columns;
  
        return columnsGroup;
    }
    return null;
}

const TableRenderer = ({ pivotTable, resultSet, pivotConfig, vizOptions, fluidLayout }) => {
    const [controller,] = useAppController();
    const { appDef: { settings } } = controller;
    const defaultDateFormat = (settings && settings.dateFormat) || "DD/MM/YYYY";
    const tableRef = useRef(null);
    const analyticsContext = useAnalyticsContext();
    const widgetItemContext = useWidgetItemContext();
    const { columns: columnsDef, config } = vizOptions;

    useEffect(() => {
        if (analyticsContext)
            analyticsContext.vizRef.current = tableRef.current;
        else if (widgetItemContext)
            widgetItemContext.vizRef.current = tableRef.current;
    }, [tableRef])

    if (!resultSet) {
        return (
            <MDBox width="100%" height="100%" display="flex" alignItems="center" justifyContent="center" flexDirection="column">
                <Icon sx={{ color: "#d0cdcd", fontSize: "64px!important" }}>leaderboard</Icon>
                <MDTypography variant="subtitle2" color="text">No Data</MDTypography>
            </MDBox>
        );
    }

    const rowCount = resultSet.tablePivot()?.length || 0;
    const isPivotTable = pivotTable && rowCount > 0;
    const tableColumns = resultSet.tableColumns(isPivotTable ? pivotConfig : undefined);
    let measures = resultSet.loadResponse?.pivotQuery?.measures;
    let selectedColumnKeys = columnsDef?.map(c => c.name) || [];

    // filter unselected dimensions 
    if (resultSet.loadResponse?.pivotQuery?.dimensions)
        resultSet.loadResponse.pivotQuery.dimensions = resultSet.loadResponse.pivotQuery.dimensions.filter(d => selectedColumnKeys.includes(d));

    const rows = resultSet.tablePivot(isPivotTable ? pivotConfig : undefined).map((row) => {
        let r = {};
        if (isPivotTable && measures) {
            measures?.map(measureName => {
                let measureVal = Object.keys(row).filter(k => k.endsWith(measureName)).reduce((sum, k) => toNumber(row[k] || 0) + sum, 0);
                r[measureName.replace(/,/g, "__").replace(/\./g, "__")] = measureVal;
            })
        }
        Object.keys(row).forEach((c) => {
                r[c.replace(/,/g, "__").replace(/\./g, "__")] = row[c];
        });
        return r;
    });

    let columns = isPivotTable ?
        tableColumns?.filter((col) => col?.children || selectedColumnKeys.includes(col.key))?.map(
            (col) => {
                const colDef = columnsDef?.find(c => c.name === col.key)
                return buildColumns(isPivotTable, defaultDateFormat, measures || [], columnsDef, colDef, col);
            }
        )
        : columnsDef?.map(
            (colDef) => {
                const col = tableColumns?.find(c => colDef.name === c.key);
                return buildColumns(isPivotTable, defaultDateFormat, measures || [], columnsDef, colDef, col);
            }
        );

    // config?.config for backward compatablility
    const grandTotalType = config?.config?.grandTotalType || config?.grandTotalType;

    if (isPivotTable
        && (grandTotalType === "showGrandTotals" || grandTotalType === "showRowGrandTotals")
        && measures
    ) {
        const grandTotalColumn = buildGrandTotalsColumnsGroup(
            measures,
            pivotConfig,
            columnsDef
        );
        if (grandTotalColumn)
            columns.push(grandTotalColumn);
    }

    const columnsMemo = useMemo(() => columns, [pivotTable, resultSet, pivotConfig, vizOptions]);
    const rowsMemo = useMemo(() => rows, [pivotTable, resultSet, pivotConfig, vizOptions]);

    return <YADataTable ref={tableRef} fluidLayout={fluidLayout} columns={columnsMemo} data={rowsMemo} totalType={grandTotalType} exportFileName="Analytics" {...(config || {})} />
}

export default TableRenderer;